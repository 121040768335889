import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";

const HeaderTop = ({ borderStyle }) => {
  const currency = useSelector((state) => state.currency);
  return (
    <div
      className={clsx(
        'header-top-wap',
        borderStyle === 'fluid-border' && 'border-bottom'
      )}
    >
      <LanguageCurrencyChanger currency={currency} />
      <div className="header-offer">
        {/* <p>
          Free delivery on order over{" "}
          <span>
            {currency.currencySymbol + (200 * currency.currencyRate).toFixed(2)}
          </span>
        </p> */}
        {/* <img
          alt
          src="/assets/img/logo/flag.png"
          style={{ width: '50px' }}
        ></img> */}
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
